#create{
    /* float:right */
    background-color: #9ACD32;
    border: 2px solid grey;
    color: black;

}
#cancel{
    background-color: white;
    border: 2px solid grey;
    color:black
    
}
.buttons{
    display: flex;
}

.export_btn {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border-radius: 4px;
    height: 40px;
}