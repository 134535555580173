
.right{
    float:right;
   
}
input[type="date"] {
    position: relative;
}

.form-group input[type="date"]:before {
    position: absolute;
    content: attr(data-date);
    display: inline-block;
    color: black;
}

/* .form-group input::-webkit-datetime-edit, input::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;
} */

input::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 5px;
    color: black;
    opacity: 1;
}
